/**
 * @file
 * Table header behavior.
 *
 * @see tableheader.js
 */

table.sticky-header {
  z-index: 500;
  top: 0;
  margin-top: 0;
  background-color: #fff;
}
