/**
 * @file
 * Inline items.
 */

.container-inline label:after,
.container-inline .label:after {
  content: ":";
}
.form-type-radios .container-inline label:after,
.form-type-checkboxes .container-inline label:after {
  content: "";
}
.form-type-radios .container-inline .form-type-radio,
.form-type-checkboxes .container-inline .form-type-checkbox {
  margin: 0 1em;
}
.container-inline .form-actions,
.container-inline.form-actions {
  margin-top: 0;
  margin-bottom: 0;
}
