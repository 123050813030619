/**
 * @file
 * Utility classes to assist with Javascript functionality.
 */

/**
 * For anything you want to hide on page load when JS is enabled, so
 * that you can use the JS to control visibility and avoid flicker.
 */
.js .js-hide {
  display: none;
}

/**
 * For anything you want to show on page load only when JS is enabled.
 */
.js-show {
  display: none;
}
.js .js-show {
  display: block;
}
