@import "~@odw/lnc-styles/lib/scss/abstracts/_global";

.cdk-overlay-container{
  @import "~@odw/lnc-styles/lib/scss/base/_global";
  @import "~@angular/cdk/overlay-prebuilt";
  @import "~@odw/lnc-styles/lib/scss/vendor/_global";
}

huitab-toast{
  lnc-web-toast{
    .mobile\:px-24{
      padding-left: 24px;
      padding-right: 24px;
    }
    .lnc-overlay-wrapper{
      display: flex;
      flex-direction: row;
      border-radius: 4px;
      align-items: center;
      justify-content: space-between;
      background-color: #60616c !important;
      span{
        font-size: 1rem;
      }
      lnc-icon{
        margin-left: 16px;
      }
    }
  }
}

#header-bottom-content{
  .rotate-180 {
    transform: rotate(180deg) !important;
  }
  .row.v-center{
    align-content: center;
    align-items: center;
  }
  row-gap: 11px;
  padding-bottom: 17px;
  #tasks-content {
    margin-left: auto !important;
  }
  @media screen and (max-width: 576px) {
    row-gap: 0px !important;
    margin-top: 0px !important;
    padding-bottom: 0px;
    #tasks-content {
      margin-top: 0px !important;
      width: 100%;
      lnc-web-task {
        flex-grow: 1;
        lnc-header-button {
          width: 100%;
          button {
            justify-content: center;
          }
        }
      }
    }
  }
  lnc-web-task {
    button {
      width: 269.64px;
      justify-content: center;
      padding-left: 12px !important;
      padding-right: 6px !important;
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      column-gap: 10px !important;
      margin-right: 0px !important;
      margin-left: 0px !important;
      color: #1423DC !important;
      background-color: #f8f9fa !important;
      border-radius: 3px !important;
      p {
        font-weight: 700;
        line-height: 13.79px;
        font-size: 12px;

        @include device(mobile, tablet) {
          display: block !important;
        }
      }

      lnc-icon{
        fill: #1423DC !important;
      }

      .arrow-icon{
        min-height: 0px !important;
        min-width: 0px !important;
        svg{
          width: 12.64px;
          height: 7.82px;
        }
      }

      .arrow-icon:not(.rotate-180){
        margin-top: 4px;
      }

      @media screen and (max-width: 576px) {
        width: 100%;
      }

    }
    button:focus{
      outline: unset !important;
    }

    .tablet-and-desktop-only{
      margin-bottom: 0px;
      display: block !important;
    }
  }
}

huitab-toast{
  lnc-web-toast{
    .lnc-overlay-wrapper{
      max-width: 560px !important;
      .row{
        margin-right: 0px !important;
        margin-left: 0px !important;
      }
    }
  }
}

.cdk-overlay-container {
  .cdk-overlay-pane{
    .task-group-overlay-container{
      margin-left: 0px;
      padding: 0px;

      .task-group-overlay{
        max-width: 640px;
        margin: 0px;
        lnc-task-item{
          .label{
            font-weight: unset !important;
          }
        }
      }
    }
  }
  .cdk-overlay-connected-position-bounding-box{
    width: fit-content !important;
    margin-top: 10px !important;
    @media screen and (max-width: 576px) {
      margin-top: 5px !important;
      margin-right: 24px !important;
      margin-left: 24px !important;
    }
  }
}