/**
 * @file
 * Visual styles for item list.
 */

.item-list .title {
  font-weight: bold;
}
.item-list ul {
  margin: 0 0 0.75em 0;
  padding: 0;
}
.item-list li {
  margin: 0 0 0.25em 1.5em; /* LTR */
  padding: 0;
}
[dir="rtl"] .item-list li {
  margin: 0 1.5em 0.25em 0;
}

/**
 * Comma separated lists.
 */
.item-list--comma-list {
  display: inline;
}
.item-list--comma-list .item-list__comma-list,
.item-list__comma-list li,
[dir="rtl"] .item-list--comma-list .item-list__comma-list,
[dir="rtl"] .item-list__comma-list li {
  margin: 0;
}
