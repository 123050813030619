/**
 * @file
 * Styles for system messages.
 */

.messages {
  padding: 15px 20px 15px 35px; /* LTR */
  word-wrap: break-word;
  border: 1px solid;
  border-width: 1px 1px 1px 0; /* LTR */
  border-radius: 2px;
  background: no-repeat 10px 17px; /* LTR */
  overflow-wrap: break-word;
}
[dir="rtl"] .messages {
  padding-right: 35px;
  padding-left: 20px;
  text-align: right;
  border-width: 1px 0 1px 1px;
  background-position: right 10px top 17px;
}
.messages + .messages {
  margin-top: 1.538em;
}
.messages__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.messages__item + .messages__item {
  margin-top: 0.769em;
}
/* See .color-success in Seven's colors.css */
.messages--status {
  color: #325e1c;
  border-color: #c9e1bd #c9e1bd #c9e1bd transparent; /* LTR */
  background-color: #f3faef;
  background-image: url(../../images/icons/73b355/check.svg);
  box-shadow: -8px 0 0 #77b259; /* LTR */
}
[dir="rtl"] .messages--status {
  margin-left: 0;
  border-color: #c9e1bd transparent #c9e1bd #c9e1bd;
  box-shadow: 8px 0 0 #77b259;
}
/* See .color-warning in Seven's colors.css */
.messages--warning {
  color: #734c00;
  border-color: #f4daa6 #f4daa6 #f4daa6 transparent; /* LTR */
  background-color: #fdf8ed;
  background-image: url(../../images/icons/e29700/warning.svg);
  box-shadow: -8px 0 0 #e09600; /* LTR */
}
[dir="rtl"] .messages--warning {
  border-color: #f4daa6 transparent #f4daa6 #f4daa6;
  box-shadow: 8px 0 0 #e09600;
}
/* See .color-error in Seven's colors.css */
.messages--error {
  color: #a51b00;
  border-color: #f9c9bf #f9c9bf #f9c9bf transparent; /* LTR */
  background-color: #fcf4f2;
  background-image: url(../../images/icons/e32700/error.svg);
  box-shadow: -8px 0 0 #e62600; /* LTR */
}
[dir="rtl"] .messages--error {
  border-color: #f9c9bf transparent #f9c9bf #f9c9bf;
  box-shadow: 8px 0 0 #e62600;
}
.messages--error p.error {
  color: #a51b00;
}
