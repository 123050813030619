/**
 * @file
 * Visual styles for form components.
 */

form .field-multiple-table {
  margin: 0;
}
form .field-multiple-table .field-multiple-drag {
  width: 30px;
  padding-right: 0; /* LTR */
}
[dir="rtl"] form .field-multiple-table .field-multiple-drag {
  padding-left: 0;
}
form .field-multiple-table .field-multiple-drag .tabledrag-handle {
  padding-right: 0.5em; /* LTR */
}
[dir="rtl"] form .field-multiple-table .field-multiple-drag .tabledrag-handle {
  padding-right: 0;
  padding-left: 0.5em;
}
form .field-add-more-submit {
  margin: 0.5em 0 0;
}

/**
 * Markup generated by Form API.
 */
.form-item,
.form-actions {
  margin-top: 1em;
  margin-bottom: 1em;
}
tr.odd .form-item,
tr.even .form-item {
  margin-top: 0;
  margin-bottom: 0;
}
.form-composite > .fieldset-wrapper > .description,
.form-item .description {
  font-size: 0.85em;
}
label.option {
  display: inline;
  font-weight: normal;
}
.form-composite > legend,
.label {
  display: inline;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-weight: bold;
}
.form-checkboxes .form-item,
.form-radios .form-item {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.form-type-radio .description,
.form-type-checkbox .description {
  margin-left: 2.4em; /* LTR */
}
[dir="rtl"] .form-type-radio .description,
[dir="rtl"] .form-type-checkbox .description {
  margin-right: 2.4em;
  margin-left: 0;
}
.marker {
  color: #e00;
}
.form-required:after {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 0.3em;
  content: "";
  vertical-align: super;
  /* Use a background image to prevent screen readers from announcing the text. */
  background-image: url(../../images/icons/ee0000/required.svg);
  background-repeat: no-repeat;
  background-size: 6px 6px;
}
abbr.tabledrag-changed,
abbr.ajax-changed {
  border-bottom: none;
}
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 2px solid red;
}

/* Inline error messages. */
.form-item--error-message:before {
  display: inline-block;
  width: 14px;
  height: 14px;
  content: "";
  vertical-align: sub;
  background: url(../../images/icons/e32700/error.svg) no-repeat;
  background-size: contain;
}
