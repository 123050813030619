@charset "UTF-8";
/* surcharges bootstrap*/
.table th,
.table td {
  border-color:#f2f3f4 !important;
}
.table thead th {
  border-color:#f2f3f4 !important;
}
.table tbody + tbody {
  border-color: #f2f3f4 !important;
}

tr:not(:first-child){
  border-top: 0.1em solid #f2f3f4 !important;
}
table{
  margin-bottom: 1rem;
  border-collapse: unset;
}
.block-coupures-encours .block-title {
  margin-bottom: 33px;
  height: 21.6px;
}

.custom-bloc.widget-travaux-block {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.block-actualites-widget .maintenance-microapp{
  padding: 1em;
}

.custom-bloc.widget-travaux-block h4,
.block-coupures-encours .maintenance-microapp h4,
.block-actualites-widget .maintenance-microapp h4,
.block-actualites-widget .construction-microapp h4,
.block-bottom-widget .maintenance-microapp h4
{
  font-size: 12px;
}
.custom-bloc.widget-travaux-block .block-title {
  font-size: 18px;
  line-height: normal;
}.custom-bloc.widget-travaux-block span.icomoon::before {
  width: 22px;
  font-size: 22px;
  margin-right: 0;
}
.custom-bloc.widget-travaux-block span.recap-text {
  font-size: 10px;
}
.custom-bloc.widget-travaux-block .wt-waiting-room {
  display: table;
  height: 100%;
  width: 100%;
  padding-bottom: 30px;
}
.custom-bloc.widget-travaux-block .wt-waiting-room > p {
  display: table-cell;
  vertical-align: middle;
  color: #005eb8;
  font-size: 16px;
}
.custom-bloc.widget-travaux-block #wt-summary-total {
  position: absolute;
  font-size: 42px;
  color: #005eb8;
  font-weight: 700;
  display: flex;
}
.custom-bloc.widget-travaux-block #wt-summary-chart {

  height: 150px;
  max-width: 150px;
}
.custom-bloc.widget-travaux-block .table.wt-chantiers {
  font-weight: 100;
  font-size: 10px;
}
.custom-bloc.widget-travaux-block .wt-content caption {
    caption-side: top;
    font-size: 12px;
    font-weight: bold;
    color: #191919;
    line-height: 1.2;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.custom-bloc.widget-travaux-block .wt-content-zero caption {
  caption-side: top;
  font-size: 12px;
  font-weight: bold;
  color: #191919;
  line-height: 1.2;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.custom-bloc.widget-travaux-block .table.wt-chantiers tr:first-child td {
  border-top: none;
}
.custom-bloc.widget-travaux-block .table.wt-chantiers .chantier-statut {
  text-align: right;
}
.custom-bloc.widget-travaux-block .table.wt-chantiers .chantier-lien a {
  font-size: 0.9rem;
}
.custom-bloc.widget-travaux-block .table.wt-chantiers td {
  vertical-align: middle;
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.actualites-widget-microapp {
  height: 100%;
}
.block-actualites-widget .construction-microapp .block-title {
  margin-bottom: 0;
}
.block-actualites-widget .construction-microapp p {
  margin-bottom: 0;
}
.block-actualites-widget .construction-microapp-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 1100px) {
  .custom-bloc.widget-travaux-block .table.wt-chantiers td {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
  }
}
.custom-bloc.widget-travaux-block .wt-content h4 {
  font-weight: bold;
}
.custom-bloc.widget-travaux-block .summary-container {
  min-height: 205px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  flex: 0 0 25%;
  padding: 0;
  margin: 0;
  margin-right: 20px;
}
.custom-bloc.widget-travaux-block .details-container {
    flex: 1 1;
    margin: 0;
    flex-direction: column;
  }
.custom-bloc.widget-travaux-block #nb-raccord-client.recap-data {
  color: #709a00;
}
.custom-bloc.widget-travaux-block #nb-modif-ouvrage.recap-data {
  color: #005eb8;
}
.custom-bloc.widget-travaux-block #nb-modern-reseau.recap-data {
  color: #00a3e0;
}
.custom-bloc.widget-travaux-block .travaux-recap {
  background: #f2f3f4 0 0 no-repeat padding-box;
  display: flex;
  width: 100%;
  padding-top: 18px;
}
.custom-bloc.widget-travaux-block .travaux-recap p {
  font-size: 10px;
}
.custom-bloc.widget-travaux-block .travaux-recap img {
  height: 20px;
  width: 20px;
  vertical-align: sub;
}
.custom-bloc.widget-travaux-block .travaux-recap span.recap-data {
  color: #005eb8;
  font-size: 17px;
  font-weight: 700;
  /*vertical-align: baseline;*/
  padding-left: 5px;
  padding-right: 5px;
}
.custom-bloc.widget-travaux-block .travaux-recap .recap-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-bloc.widget-travaux-block .travaux-recap .recap-container > span {
  display: table-cell;
  vertical-align: middle;
}
@media screen and (max-width: 992px) {
  .custom-bloc.widget-travaux-block .travaux-recap {
    padding-top: 20px;
  }
  .custom-bloc.widget-travaux-block .travaux-recap .recap-container span {
    padding: 0 5px;
  }
  .custom-bloc.widget-travaux-block .travaux-recap .recap-container img {
    height: 25px;
    width: 25px;
  }
  .custom-bloc.widget-travaux-block .travaux-recap .recap-container .recap-text {
    font-size: 12px;
  }
  .custom-bloc.widget-travaux-block .travaux-recap .recap-container .recap-data {
    font-size: 1.4rem;
  }
  .custom-bloc.widget-travaux-block .summary-container {
    min-height: 0px;
    height: 150px;
    width: 150px;
    display: flex;
    justify-content: center;
    flex: 0 0 100%;
    align-items: center;
  }
  .custom-bloc.widget-travaux-block .details-container {
    display: none;
  }
}
@media screen and (min-width: 993px) {
  .custom-bloc.widget-travaux-block .travaux-recap > div {
    margin: auto;
  }
}
@media screen and (min-width: 576px) and (max-width: 993px) {
  .custom-bloc.widget-travaux-block .travaux-recap > div {
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .custom-bloc.widget-travaux-block .travaux-recap {
    padding-left: 0 !important;
  }
  .custom-bloc.widget-travaux-block .travaux-recap .recap-container img {
    height: 20px;
    width: 20px;
  }
}
@media screen and (max-width: 992px) {
  .custom-bloc.widget-travaux-block .travaux-recap {
    background: none;
    border-top: 2px solid #f2f3f4;
  }
  .custom-bloc.widget-travaux-block .block-title,
  .custom-bloc.maintenance-microapp .block-title {
    display: flex;
    justify-content: center;
    text-align: center;

  }
  .custom-bloc.widget-travaux-block .block-title span,
  .custom-bloc.maintenance-microapp .block-title span {
    display: none;
  }
  .custom-bloc.widget-travaux-block .wt-content a {
    width: 100%;
  }
  .widget {
    flex-direction: column;
  }
  .widget-2d-mobile {
    width: 100%;
    padding-left: 74px;
  }
}

ol.breadcrumb {
  padding: 0.75rem 1rem;
  margin-bottom: 0px;
  list-style: none;
  background-color: #f2f3f4;
  border-radius: 0;
}
ol.breadcrumb li {
  line-height: 0.5em;
  color: #b3c0cb;
}
ol.breadcrumb li a {
  color: #b3c0cb;
}
ol.breadcrumb li.board-title {
  color: #709a00;
  font-weight: bold;
}
ol.breadcrumb li.board-title + li::before {
  margin-left: 0.5em;
  margin-right: 0.5em;
  content: "|";
}
ol.breadcrumb li.board-title + li::after {
  margin-left: 0.2em;
  margin-right: 0.2em;
  content: ">";
}
ol.breadcrumb li:last-child:not(.board-title)::after {
  display: none;
}
ol.breadcrumb li:last-child:not(.board-title):last-child {
  color: #191919;
}
ol.breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 0.1rem;
}
ol.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  display: none;
}
ol.breadcrumb .breadcrumb-item + .breadcrumb-item::after {
  content: ">";
  margin-left: 0.2em;
  margin-right: 0.2em;
}

#liste-collectivite .btn.btn-light {
  font-size: 12px;
  padding: 0.6rem 0.75rem;
}
#liste-collectivite .dropdown-menu {
  background-color: #fafbfb;
  border: 0.1em #fafbfb;
  width: 200px;
}
#liste-collectivite .dropdown-menu a.dropdown-item {
  padding-left: 0;
}
#liste-collectivite .dropdown-menu a.dropdown-item.active {
  color: #005eb8;
  background-color: transparent;
}
@media screen and (max-width: 577px) {
  #liste-collectivite .dropdown-menu .dropdown-menu {
    left: 5px !important;
    text-align: center;
  }
  #liste-collectivite .dropdown-menu .dropdown-menu.show {
    top: 1px !important;
  }
}
#liste-collectivite .btn-liste-collectivite {
  color: #005eb8;
  background-color: #fafbfb;
  border-color: #fafbfb;
  min-width: 250px;
  border-radius: unset;
  font-size: 12px;
  padding: 1px;
}
#liste-collectivite .btn-liste-collectivite:hover {
  color: #005eb8;
}
#liste-collectivite .btn-liste-collectivite:focus {
  box-shadow: none;
  color: #005eb8;
}
#liste-collectivite .btn-liste-collectivite span.selected-item {
  font-weight: bold;
  padding: 5px 10px;
  line-height: 2rem;
}
#liste-collectivite .btn-liste-collectivite span.icomoon {
  padding: 14px;
  font-size: 7px;
  background-color: #709a00;
  color: #fefefe;
  margin: 0;
}
@media screen and (max-width: 577px) {
  #liste-collectivite .btn-liste-collectivite {
    width: 50%;
    border: none;
  }
  #liste-collectivite .btn-liste-collectivite span.selected-item {
    white-space: break-spaces;
    line-height: 1.5;
    display: inline-block;
    padding: 0 40px 0 0;
  }
  #liste-collectivite .btn-liste-collectivite span.icomoon {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    float: none !important;
    display: inline-flex;
    align-items: center;
  }

  .dropdown-menu li > a{
    margin-left: 0.75rem;
  }

}

.btn {
  font-size: 0.8rem;
  font-weight: bold;
}
.btn.btn-enedis,
.btn.btn-primary {
  background-color: #00a3e0;
  box-shadow: inset 0 0 0 1px #007aa7;
  border-radius: 3px;
  border: unset;
  color: #fff;
  padding: 9px 16px;
}
.btn.btn-enedis{
  display: flex;
  align-self: center;
  /* si affichage largeur ecran mobile
  width: 100%;*/
  justify-content: center;
  margin-top: auto;
}

.btn-modal-reject{
  line-height: 1.5 !important;
  background-color: rgb(248, 250, 251) !important;
  font-size: 115% !important;
  box-shadow: inset 0 0 0 1px rgb(217 223 229) !important;
  height: 48px;
}

.btn-modal-accept{
  line-height: 1.5 !important;
  font-size: 115% !important;
  height: 48px;
}

@media screen and (min-width: 576px) and (max-width: 993px)  {
  .btn.btn-enedis {
    align-self: center;
    width: max-content;
  }

  .btn-modal-reject{
    width: 90% !important;
  }

  .btn-modal-accept{
    width: 90% !important;
  }
}
@media screen and (min-width: 993px) {
  .btn.btn-enedis {
    align-self: self-end;
    width: max-content;
    justify-self: self-end;
    align-items: flex-end;
  }

  .btn-modal-reject{
    width: 35% !important;
    align-items: center !important;
  }

  .btn-modal-accept{
    width: 35% !important;
    align-items: center !important;
  }
}

.btn.btn-enedis:hover,
.btn.btn-primary:hover {
  background-color: #007aa7;
}
.btn.btn-light {
  border: 1px solid #005eb8;
  border: unset;
  color: #005eb8;
}
.btn.btn-secondary {
  background-color: #fff;
  color: #4d6071;
  border-color: #b3c0cb;
  min-width: 9em;
}
.btn.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 86, 179, 0.25);
}
.btn.btn-enedis:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 86, 179, 0.25);
}
.btn.btn-secondary:hover {
  background-color: #f2f3f4;
}
.btn.btn-disabled {
  background-color: #8ea1b1;
  color: #ffffff;
  border-color: #8ea1b1;
}
.btn.btn-disabled:hover {
  cursor: default;
  color: #ffffff;
  background-color: #8ea1b1;
  border-color: #8ea1b1;
}

.dropdown .dropdown-menu:hover,
.dropdown .dropdown-menu:active {
  color: #005eb8;
}
.dropdown .dropdown-menu a.nav-link,
.dropdown .dropdown-menu a.dropdown-item {
  color: #8ea1b1;
  display: block;
  text-transform: none;
}
.dropdown .dropdown-menu a.nav-link .icomoon::before,
.dropdown .dropdown-menu a.dropdown-item .icomoon::before {
  color: #8ea1b1;
  text-align: center;
}
.dropdown .dropdown-menu a.nav-link.active .icomoon::before {
  color: #005eb8;
}
.dropdown .dropdown-menu a.nav-link.active,
.dropdown .dropdown-menu a.nav-link.is-active,
.dropdown .dropdown-menu a.dropdown-item.active {
  color: #005eb8 !important;
  text-decoration: none;
  background-color: transparent;
  font-weight: bold;
}

/**
  Override w-75 of bootstrap as it only exists for 25, 50, 75 and 100
 */
.w-80 {
  width: 80% !important;
}

/* Ajout à Bootstrap */
.justify-content-evenly {
  justify-content: space-evenly !important;
}

body {
  font-family: "Ubuntu", serif;
  font-size: 12px;
}

.container-xl {
  max-width: 1300px;
}

.main-page {
  background-image: url("../img/backroud_image.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: top;
  background-color: #c9c9c9;
}
.main-page main {
  margin-bottom: 3em;
}
.main-page .page-main-content {
  background-color: #f2f3f4;
}

span.icomoon {
  margin-right: 6px;
}
span.icomoon::before {
  width: 12px;
  display: inline-block;
}
span.icomoon.ico-blue::before {
  color: #005eb8 !important;
}
span.icomoon.ico-grey::before {
  color: #b3c0cb !important;
}
a:hover .icomoon::before {
  color: inherit !important;
}
.block-travaux-encours .icomoon.icon-excavator,
.block-bottom-widget .icomoon.icon-support-agent-colloc
{
  margin-right: 12px;
}
.block-coupures-encours .icomoon.icon-flash {
  position: relative;
  top: 2px;
}

.block-actualites-widget .icomoon.icon-newspaper-variant {
  margin-right: 15px;
}

[is^="modal-enedis"],
.modal-enedis {
  padding: 2rem;
  text-align: center;
  font-size: 1rem;
}
@media (max-width: 767.98px) {
  [is^="modal-enedis"],
  .modal-enedis {
    padding: 0.5rem;
  }
}
[is^="modal-enedis"] .modal-header,
.modal-enedis .modal-header {
  display: block;
  border-bottom: none;
  padding: 0;
  margin-bottom: 1em;
  text-align: center;
  color: #0962b3;
  font-weight: bold;
  justify-content: center;
}
[is^="modal-enedis"] .modal-header .modal-title,
.modal-enedis .modal-header .modal-title {
  font-weight: bold;
  text-transform: uppercase;
  color: #005eb8;
  font-size: 1.3rem;
}
[is^="modal-enedis"] .modal-header .icomoon,
.modal-enedis .modal-header .icomoon {
  font-size: 2rem;
}
[is^="modal-enedis"] .modal-content,
.modal-enedis .modal-content {
  border-radius: 0;
  padding: 1rem 0;
}
[is^="modal-enedis"] .modal-content .modal-header,
[is^="modal-enedis"] .modal-content .modal-body,
.modal-enedis .modal-content .modal-header,
.modal-enedis .modal-content .modal-body {
  padding-right: 6rem;
  padding-left: 6rem;
}
@media (max-width: 767.98px) {
  [is^="modal-enedis"] .modal-content .modal-header,
  [is^="modal-enedis"] .modal-content .modal-body,
  .modal-enedis .modal-content .modal-header,
  .modal-enedis .modal-content .modal-body {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
[is^="modal-enedis"] .modal-footer,
.modal-enedis .modal-footer {
  justify-content: center;
  border-top: none;
  /*margin-bottom: 2em;*/
  border-top: none;
}
[is^="modal-enedis"] .cgu-warning,
.modal-enedis .cgu-warning {
  font-weight: bold;
}
[is^="modal-enedis"] .btn-enedis,
.modal-enedis .btn-enedis {
  font-size: 0.8rem;
  min-width: 9em;
}
[is^="modal-enedis"] .btn-primary:focus,
.modal-enedis .btn-primary:focus {
  box-shadow: none;
}
[is^="modal-enedis"] .btn-secondary,
.modal-enedis .btn-secondary {
  min-width: 9em;
}

#cguModal {
  font-size: 14px;
}
#cguModal .modal-dialog {
  max-width: 60em;
}
#cguModal .modal-header {
  margin-top: 2em;
}
#cguModal .btn-primary {
  min-width: 9em;
}
@media screen and (max-width: 991px) {
  #cguModal .modal-body {
    padding-right: 2em;
    padding-left: 2em;
  }
}

@media screen and (min-width: 992px) {
  /*#cguModal {*/
  /*  top: 18em;*/
  /*}*/
}

.account-menu-container > div {
  display: inline-block;
}

.dropdown.account-menu > .dropdown-menu:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: white;
  position: absolute;
  top: -7px;
  right: 9px;
}
.dropdown.account-menu .dropdown-menu {
  position: absolute;
  top: 35px;
  left: auto;
  right: 0;
}
.dropdown.account-menu .dropdown-menu.dropdown-item:hover {
  color: #005eb8;
}
.dropdown.account-menu .dropdown-menu a {
  padding-left: 1em;
}
.dropdown.account-menu .dropdown-menu a:hover {
  text-decoration: none;
}
.dropdown.account-menu .dropdown-menu a:hover span.welcome-text {
  text-decoration: underline;
}
.dropdown.account-menu .dropdown-menu a:hover,
.dropdown.account-menu .dropdown-menu a:focus {
  background-color: transparent;
}
.dropdown.account-menu .dropdown-menu a .dropdown-toggle {
  white-space: normal;
}
.dropdown.account-menu .welcome-text {
  font-size: 13px;
  margin-right: 1em;
  min-width: 180px;
}

nav.menu--main .navbar-nav .dropdown-menu a.nav-item.is-disabled {
  cursor: default;
}
nav.menu--main .navbar-nav .dropdown-menu a.nav-item.is-disabled:hover {
  color: #8ea1b1;
  cursor: default;
}

.header-fullhtml {
  min-height: 0;
}

.main-footer {
  background-color: #292929;
}
.main-footer .region-footer {
  padding: 0;
}
.main-footer .region-footer nav .navbar-nav {
  flex-direction: row;
  flex: 1;
  margin: auto !important;
  display: flex;
  justify-content: space-between;
}
.main-footer .region-footer nav .navbar-nav li a.active {
  font-weight: bold;
}
.main-footer .region-footer nav .navbar-nav li.nav-item a.nav-link {
  text-transform: none;
  color: #ffffff;
  cursor: pointer;
}
@media screen and (max-width: 991px) {
  .main-footer .region-footer nav .navbar-nav {
    flex-direction: column;
    gap: 1.5rem;
  }
}
.main-footer li .nav-link {
  padding-left: 0;
  margin-right: 1em;
}
.main-footer .logo-footer img {
  height: auto;
  width: 110px;
}

.footer-gap {
  gap: 2rem;
}

.footer-icon {
  padding: 0 !important;
  margin: 0;
}

@media screen and (max-width: 576px) {
  .main-footer footer {
    padding-bottom: 2rem;
  }
  .main-footer li,
  .main-footer li .nav-link {
    display: block;
    text-transform: none;
  }
  .main-footer .navbar-nav li.nav-item a {
    color: #ffffff;
  }
  .main-footer .logo-footer {
    padding-left: 0;
    text-align: center;
  }
}

header {
  min-height: 14em;
}
@media screen and (max-width: 991px) {
  header {
    min-height: 0;
  }
}
header .menu-separator {
  border-left: 2px solid #fff;
  margin: 4px 0;
}
@media (max-width: 991px) {
  header .menu-separator {
    display: none !important;
  }
}

/**
  Gestion alignements block HP
 */
.div-blocks > [class*="block-"] .custom-bloc {
  height: 100%;
}
.div-blocks > [class*="block-"] > .embedded-entity {
  height: 100%;
}
.div-blocks > [class*="block-"] > .embedded-entity > div {
  height: 100%;
}

.custom-bloc {
  padding: 1em;
}
.custom-bloc .block-title {
  color: #005eb8;
  font-size: 14px;
  font-weight: bold;
}
.custom-bloc p,
.actualites-widget-microapp .maintenance-microapp p{
  font-size: 12px;
  color: #4d6071;
}
.custom-bloc .p-aide {
  font-size: 12px;
  color: #4d6071;
  font-weight: bold;
}
.block-widget h2,
.block-actualites-widget h2,
.block-bottom-widget h2 {
  color: #005eb8;
  font-size: 12px;
  font-weight: bold;
}
.block-actualites-widget h2 {
  padding-bottom: 1.5em;
}
.block-widget p {
  font-size: 10px;
  color: #4d6071;
}

.block-widget,
.block-bottom-widget {
  flex-wrap: nowrap;
}
.div-blocks {
  justify-content: space-between;
}
.block-widget:hover {
  box-shadow: 0px 3px 6px #00000029;
}
.img-carto {
  height: 52px !important;
  width: 70px !important;
}
.mobile-only {
  display: none;
}
.desktop-only {
  display: revert;
}
.div-blocks .block-raccordement {
  /*max-width: 35%;*/
}
.div-blocks .block-service-donnees {
  max-width: 25%;
}
.div-blocks .block-service-donnees .service-donnees-bloc {
  position: relative;
  height: 100%;
}
.div-blocks .block-assistance {
  max-width: 40%;
}
.bloc-height {
  max-height: 315px;
}
.div-blocks .block-assistance .div-assistance-contact {
  border-right: 1px solid #b3c0cb;
}
.div-blocks .block-assistance .assis-video {
  max-width: 13em;
}
.div-blocks .block-travaux-encours {
  max-width: calc(50% - 0.25rem);
}
@media screen and (min-width: 992px) {
  .div-blocks .block-assistance .div-assistance-video,
  .div-blocks .block-assistance .div-assistance-contact {
    padding-bottom: 30px;
    /* Contrebalance le bouton en absolute */
  }
  .div-blocks .block-service-donnees .service-donnees-content {
    padding-bottom: 60px;
    /* Contrebalance le bouton en absolute */
  }
  .div-blocks .block-raccordement .div-raccordement {
    padding-bottom: 30px;
    /* Contrebalance le bouton en absolute */
  }
  .div-blocks .block-service-donnees .text-center {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: -12px;
    /* Cancel padding */
  }
  .div-blocks .block-raccordement .div-raccordement .text-center {
    position: absolute;
    bottom: 0;
    left: -5px;
    /* Cancel padding */
  }
  .div-blocks p.p-button-container {
    position: absolute;
    bottom: 1rem;
    width: 100%;
    margin-bottom: 0;
  }
  .div-blocks .block-assistance p.p-button-container {
    bottom: -10px;
  }
}
@media screen and (max-width: 991px) {
  .div-blocks .block-assistance .div-assistance-video p.p-button-container,
  .div-blocks .service-donnees-bloc .text-center > p,
  .div-blocks .div-raccordement .text-center > p {
    margin-bottom: 0;
  }
}
.block-travaux-encours,
.block-coupures-encours,
.block-actualites-widget {
  border: 1px solid #005eb8;
  min-height: 25em;
  background-color: #ffffff;
}
.block-actualites-widget {
  border: none;
}

.div-blocks .block-assistance .div-assistance-video img {
  max-width: 13em;
}

.embedded-entity {
  width: 100%;
}

.no-margin-top{
  margin-top: 0 !important;
}

.no-margin-bot{
  margin-bot: 0 !important;
}

.maintenance-microapp {
  font-family: "Ubuntu", sans serif;
  background-color: #ffffff;
  font-size: 12px;
  margin-top: 46px;
  margin-bottom: 20px;
}

.maintenance-microapp h4 {
  color: #005eb8;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
}

.block-assistance .maintenance-microapp {
  margin-top: 0;
  margin-bottom: 0;
}

.maintenance-microapp .img-desktop > img,
.construction-microapp .img-desktop > img {
  width: 100%;
  max-width: 1090px;
}
.maintenance-microapp .img-mobile,
.construction-microapp .img-mobile {
  display: none;
}

.block-coupures-encours .block-title,
.block-actualites-widget .block-title,
.block-bottom-widget .block-title
{
  color: #005eb8;
  font-size: 18px;
  font-weight: bold;
}

.actualites-widget-microapp .construction-microapp .img-mobile {
  overflow: hidden;
}

@media screen and (max-width: 991px) {
  .div-blocks .widget-link,
  .div-blocks .block-bottom-widget,
  .div-blocks .block-raccordement,
  .div-blocks .block-service-donnees,
  .div-blocks .block-assistance {
    max-width: 100%;
    background-color: white;
  }
  .div-blocks .block-bottom-widget,
  .div-blocks .block-raccordement,
  .div-blocks .block-service-donnees {
    margin-bottom: 1.5em;
  }
  .div-blocks .block-assistance .div-assistance-contact {
    border-right: none;
    padding-right: 0;
    border-bottom: 1px solid #b3c0cb;
  }
  .div-blocks .block-assistance .div-assistance-video {
    margin-left: 0;
    margin-top: 2em;
  }
  .custom-bloc,
  .block-widget {
    min-height: 84px;
  }
  .block-bottom-widget {
    flex: 0 0 100%;
  }
  .div-blocks .block-raccordement .div-raccordement img {
    max-width: 10em;
  }
  .div-blocks .block-raccordement .custom-bloc {
    min-width: 0;
  }
  .maintenance-microapp .img-desktop,
  .construction-microapp .img-desktop {
    display: none;
  }
  .maintenance-microapp .img-mobile,
  .construction-microapp .img-mobile {
    display: block;
  }
  .landscape-img {
    display: none;
    justify-content: center;
    max-width: 599px;
    margin-left: auto;
    margin-right: auto;
  }
  #cguModal .modal-content{
    max-width: 100%;
    height: auto;
  }

  .actualites-widget-microapp .construction-microapp .img-mobile {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 820px) {
  .main-page {
    background-color: white;
  }
  .mobile-only {
    display: revert;
  }
  .mobile-only p {
    color: #0962b3;
  }
  .desktop-only {
    display: none;
  }
}
.dropdown-menu a svg,
#mobile-nav .navbar-nav a svg {
  margin-right: 0.6em;
}

.navbar-brand img {
  width: 200px;
  height: 28px;
}

.logo-mobile {
  display: none;
}

.navbar-expand-lg .navbar-nav ul.main-menu-fonctionnalites {
  padding-left: 0;
  padding-bottom: 5px;
}
.navbar-expand-lg .navbar-nav ul.main-menu-fonctionnalites > li a {
  padding-left: 0;
}
.navbar-expand-lg .navbar-nav .sous-item-title {
  color: #4d6071;
}

ul.main-menu-fonctionnalites > li {
  list-style-type: none;
}

.region-nav-main .navbar-nav > li.nav-item > a {
  color: #ffffff;
  margin-left: 0;
  text-transform: uppercase;
  padding-top: 5px;
  padding-bottom: 5px;
}
.region-nav-main .navbar-nav .dropdown-menu .service-prioreno-item a img{
  width: 12px;
  height: 12px;
  margin-right: 6px;
}
.region-nav-main .navbar-nav .dropdown-menu a.nav-link:hover {
  color: #005eb8;
}
.region-nav-main .navbar-nav .dropdown-menu a.nav-link:hover .icomoon::before {
  color: #005eb8;
}
.region-nav-main .navbar-nav > li.nav-item > a.active,
.region-nav-main .navbar-nav > li.nav-item > a:hover {
  color: #709a00;
}
.region-nav-main .navbar-nav > li.nav-item:hover > a {
  color: #709a00;
}
.region-nav-main .navbar-nav > li.nav-item > ul li .nav-link {
  padding-bottom: 0.1em;
}
.region-nav-main nav .navbar-nav > li {
  text-align: center;
}

nav.menu--main .navbar-nav li.main-menu-left-border {
  border-left: 2px solid white;
}
nav.menu--main .navbar-nav li.main-menu-is-external {
  text-decoration: underline;
}
nav.menu--main .navbar-nav .nav-link {
  padding-top: 0;
  padding-bottom: 0;
}

.region-nav-main nav .navbar-nav > li.dropdown > a > span,
#liste-collectivite .dropdown-menu > a {
  white-space: normal;
}

#desktop-nav .navbar-nav > li.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 0;
  margin: 0;
  left: 5px;
  font-size: 12px;
  white-space: nowrap;
}

.navbar-nav > li > .dropdown-menu:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: white;
  position: absolute;
  top: -7px;
  left: 9px;
}

button.navbar-toggler {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
}
button.navbar-toggler:focus {
  outline: none;
}

#mobile-nav a.is-active {
  /* use link_active */
  color: #005eb8 !important;
  text-decoration: none;
  background-color: transparent;
  font-weight: bold;
}

.btn-help {
  color: #005eb8;
  background-color: white;
}

.navbar-expand-lg {
  padding: 0;
}

.dropdown-toggle::after {
  display: none;
}

.page-title {
  display: none;
  color: #709a00;
  font-weight: bold;
  background-color: #f2f3f4;
  padding: 0.75rem 1rem;
  line-height: 1em;
}

.container-contenu {
  background: #f9fafb 0% 0% no-repeat padding-box;
  padding: 2rem;
  font-family: Ubuntu, serif;
  height: 755px;
}

.container-auto {
  height: auto !important;
}

.page-title2 {
  color: #709a00;
  font-size: 35px;
  font-weight: bold;
}

.page-article {
  font-weight: bold;
  font-size: 24px;
  color: #005eb8;
  text-transform: uppercase;
  margin-top: 2rem;
}

#firstArticle {
  margin-top: 2.5rem !important;
}

.page-subtitle {
  font-weight: bold;
  font-size: 16px;
  color: #33404b;
}

.page-subtitle-cursor {
  font-weight: bold;
  font-size: 16px;
  color: #33404b;
  cursor: pointer;
}

.contenu {
  font-size: 16px;
}

.contenu-widget{
  color: #33404b;
  line-height: 24px;
  font: 16px "Ubuntu" !important;
}

.contain {
  margin-bottom: 1.5rem;
}

.contenu-smaller {
  font-size: 14px !important;
}

.contenu-font {
  font-weight: normal !important;
}

.contenu-liens {
  font-size: 16px;
  text-decoration: none;
  color: #656c73 !important;
}

.nav-additional-container {
  flex-grow: 50;
  flex-direction: row-reverse;
  text-align: right;
}

.div-menu {
  margin: 23px 0 0;
  width: 100%;
}

.div-toggler #hide-icon,
#mobile-nav {
  display: none;
}

.number-circle {
  border-radius: 50%;
  font-size: 10px;
  padding: 3px 6px;
  background: #709a00;
  color: #ffffff;
  text-align: center;
}

a.dropdown-item.active .number-circle,
a.nav-link.active .number-circle {
  color: #005eb8;
}

div.help-mobile {
  background-color: #00a3e0;
  height: 33px;
  font-size: 14px;
  text-decoration: underline;
}
div.help-mobile div {
  padding-top: 0.5em;
}

@media screen and (min-width: 577px) and (max-width: 991px) {
  .div-menu {
    margin-bottom: 2em;
  }

  #mobile-nav {
    background-color: white;
    padding-bottom: 2em;
  }
  #mobile-nav ul.navbar-nav {
    margin: auto;
    width: 80%;
    display: block;
    -moz-column-count: 2;
    column-count: 2;
  }
  #mobile-nav ul.navbar-nav > li {
    margin-bottom: 1em;
    page-break-inside: avoid;
    -moz-column-break-inside: avoid;
    break-inside: avoid;
  }
  #mobile-nav .navbar-nav > li.nav-item > a {
    padding-bottom: 0.1em;
    padding-top: 0.1em;
  }
  #mobile-nav .navbar-nav > li.nav-item > ul li a {
    padding-bottom: 0.5em;
    padding-top: 0.1em;
  }
  #mobile-nav ul.navbar-nav > li ul {
    border: none;
    padding-left: 2em;
    padding-top: 0;
  }

  #navbar-toggler-icon {
    color: #ffffff;
  }
}
@media screen and (max-width: 991px) {
  .div-menu .div-toggler {
    color: #005eb8;
  }

  #mobile-nav.show {
    display: block;
    position: fixed;
    overflow: auto;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 1000;
    box-shadow: 0 -10px 8px rgba(0, 0, 0, 0.15);
    font-size: 1rem;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
  }
  #mobile-nav .dropdown-menu {
    font-size: 1rem;
  }
  #mobile-nav a {
    color: #8ea1b1;
  }

  #mobile-nav a:hover {
    color: #005eb8;
  }

  #mobile-nav .navbar-nav > li.nav-item > a {
    text-transform: uppercase;
  }

  #mobile-nav .navbar-nav li.nav-item ul li a {
    white-space: initial;
  }

}
@media screen and (max-width: 576px) {
  .btn-modal-reject {
    width: 90%;
  }

  .btn-modal-accept {
    width: 90%;
  }

  .page-title {
    width: 50%;
  }

  .container-contenu {
    height: 50%;
  }

  .main-page .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
  .main-page main {
    background-color: #f2f3f4;
    margin-bottom: 0;
  }
  .main-page .container-xl header nav {
    margin-left: 1em;
    margin-right: 1em;
  }

  .div-menu {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  #mobile-nav.show {
    top: 60px;
  }
  #mobile-nav .navbar-nav li {
    margin-bottom: 1em;
  }
  #mobile-nav .navbar-nav li.nav-item a {
    color: #8ea1b1;
    padding: 0;
  }
  #mobile-nav .navbar-nav li.nav-item .service-prioreno-item a img{
    width: 12px;
    height: 16px;
    margin-right: 6px;
  }
  #mobile-nav .navbar-nav li.nav-item ul {
    border: none;
    padding-left: 2em;
    padding-top: 0;
  }
  #mobile-nav .navbar-nav li.nav-item ul li {
    margin: 0;
  }
  #mobile-nav .navbar-nav li.nav-item ul li a {
    padding-top: 0.5em;
    white-space: initial;
  }

  #mobile-nav .navbar-nav li.nav-item ul li .service-prioreno-item a img{
    width: 12px;
    height: 16px;
    margin-right: 6px;
  }

  .navbar-nav li.nav-item a,
  .navbar-brand .site-title a {
    color: #005eb8;
  }

  .logo-mobile {
    display: block;
  }

  .navbar-brand .logo-desktop {
    display: none;
  }

  .main-page {
    background-image: none;
    background-color: white;
    padding: 0;
    margin-bottom: 0;
  }

  .breadcrumb {
    visibility: hidden;
    display: none;
  }

  .page-title-container {
    border-bottom: 1px solid #d9dfe5;
  }

  .page-title {
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

.div-blocks .block-coupures-encours .field--name-body {
  height: 100% !important;
}

.modal-cgu-title {
  text-align: justify;
  margin-bottom: 11px;
  line-height: 107%;
  font-family: "Calibri", sans-serif;
  font-size: 16pt;
  font-weight: 700;
}

.modal-cgu-body {
  text-align: justify;
  margin-bottom: 11px;
  font-size: 11pt;
  line-height: 107%;
  font-family: "Calibri", sans-serif;
}

.modal-cgu-break-line {
  text-align: justify;
  margin-bottom: 11px;
}

.modal-cgu-chip {
  text-align: justify;
  margin-left: 8px;
  font-size: 11pt;
  line-height: 107%;
  font-family: "Calibri", sans-serif;
}

.mobile-noscrollbar-bg {
  overflow: hidden;
}

#container-veuillez-selectionner-collectivite {
  display: none;
  justify-content: center;
  position: relative;
}

.texte-veuillez-selectionner-collectivite {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.texte-veuillez-selectionner-collectivite p {
  text-align: center;
  color: #005eb8;
  margin-top: 0.4em;
  font-weight: bold;
}

.fleche-veuillez-selectionner-collectivite {
  position: absolute;
  top: 18px;
  right: 5%;

  /* Explication du width : 50% de la largeur de l'iframe
  - 275px/2 moitié de la largeur de texte-veuillez-selectionner-collectivite
  - 16px marge entre texte et flèche
  - 5% marge avec la bordure de l'iframe */
  width: calc(50% - 275px / 2 - 16px - 5%);

  /* Explication du height : 50% de la hauteur de l'iframe
  + (155px + 0.4em + 18px + 1rem) / 2 moitié de la hauteur de texte-veuillez-selectionner-collectivite
  - (1rem + 9px) hauteur entre le bas de texte-veuillez-selectionner-collectivite et le milieu de la balise p (= margin-bottom + 1/2 line height)
  - 18px marge avec la bordure de l'iframe
  */
  height: calc(50% + (155px + 0.4em + 18px + 1rem) / 2 - (1rem + 9px) - 18px);
}

.block-coupures-encours .maintenance-microapp {
  margin: unset;
}

.non-habilite .img-mobile img {
  width: 141px;
}

@media (max-width: 1020px) {
  .non-habilite br {
    display: none;
  }
}

.widget-link .non-habilite img {
  width: 62px;
  margin: -10px 4px;
}
.widget-link .non-habilite h2 {
  color: #5b6d7c;
  margin-bottom: 7px;
}
.widget-link .non-habilite h4 {
  color: #005eb8;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 2px;
}
.widget-link.disabled {
  pointer-events: none;
}

.titre-modal{
  font-size: 1.5rem !important;
}
.contenu-modal{
  color: #33404bbf;
  font-family: Ubuntu, Light;
}

@media(min-width: 1280px){
  .espace-50{
    width:50%
  }
}
.erreur-404_container{
  margin:0 auto;
  margin-top:80px
}
@media(min-width: 1280px){
  .erreur-404_container{
    width:1090px
  }
  .erreur-404_container
  .retourAccueil_bouton{
    margin-top:110px
  }
  .erreur-404_container
  .erreur-404_image{
    width:725px;height:557px}
}
@media(min-width: 768px)and (max-width: 1279.98px) {
  .erreur-404_container {
    width:690px
  }
  .erreur-404_container
  .retourAccueil_bouton {
    margin-top:80px
  }
  .erreur-404_container .erreur-404_image {
    width:450px
  }
}
@media(max-width: 767.98px) {
  .erreur-404_container
  .image-et-bouton_retourAccueil {
    flex-direction:column
  }
  .erreur-404_container
  .retourAccueil_bouton {
    margin-top:192px
  }
  .erreur-404_container
  .erreur-404_image {
    width:495px
  }
}
.erreur-404_container
.erreur-404_texte {
  width:fit-content
}
.erreur-404_container
.erreur-404_titre {
  color:#005eb8;
  font-family:sans-serif;
  font-size:46px;
  line-height:53px;
}
.erreur-404_container
.erreur-404_sous-titre {
  font-size:24px;
  line-height:28px;
}
.erreur-404_container img {
  width:100%;
  display:block;
}
.retourAccueil_bouton {
  width:195px;
  height:46px;
}
.retourAccueil_bouton a {
  background-color:#00a3e0;
  border:1px solid #0086b8;
}
.retourAccueil_bouton a:hover {
  background-color:#0086b8;
  border:1px solid #0075aa;
  text-decoration: none;
  color: #ffffff;
}
.retourAccueil_link {
  display:inline-block;
  width:100%;
  height:100%;
  border-radius:5px;
  font-family:sans-serif;
  font-size:16px;text-align:center;
  line-height:46px;
  text-decoration:none;
}
.erreur-500-503_container {
  margin-top:100px
}.erreur-500-503_image {
   text-align:center;
   margin:0 auto 20px auto;
   height:179px;
 }
.erreur-500-503_image img {
  text-align:center;
  margin:auto;
  width:100%;
  display:block
}@media(min-width: 1280px) {
  .erreur-500-503_image-desktop {
    display:flex;
    width:1090px;
    margin:auto;
  }
}
.erreur-500-503_image-tablette {
  max-width:fit-content;
  max-height:fit-content;
  padding-top:20px;
  margin:auto;
}
@media(max-width: 767.98px) {
  .erreur-500-503_image-tablette {
    display:flex;
  }
}
@media(min-width: 768px)and (max-width: 1279.98px) {
  .erreur-500-503_image-tablette{
    display:flex;
  }
}
.erreur-500-503_paragraphe {
  max-width:700px;
  text-align:center;
}

.bold{
  font-weight: 700;
}
.bleu-enedis-500 {
  fill: #1423dc;
  color: #1423dc;
}
.lnc-text-1 {
  font-size: 1.125rem;
}
.neutre-900 {
  fill: #101014;
  color: #101014;
}
.row {
  flex-direction: row;
}
.row.h-space-between {
  justify-content: space-between;
}
.blanc {
  fill: #fff;
  color: #fff;
}

mrl-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mb-16 {
  margin-bottom: 16px;
}

.img-desktop-travaux_coupure {
  max-width: 444px;
  display: inline-block;
  padding-bottom: 24px;
}

@media(min-width: 443px) {
  .background-widget{
    visibility: visible;
    padding-bottom: 24px !important;
  }
}

@media(max-width: 443px) {
  .background-widget{
    visibility: visible;
    padding-bottom: 16px !important;
  }
}

.texte_travaux_coupure {
  line-height: 24px !important;
  margin-bottom: 8px;
  color: #212529 !important;
}