/**
 * @file
 * Resizable textareas.
 */

.resize-none {
  resize: none;
}
.resize-vertical {
  min-height: 2em;
  resize: vertical;
}
.resize-horizontal {
  max-width: 100%;
  resize: horizontal;
}
.resize-both {
  max-width: 100%;
  min-height: 2em;
  resize: both;
}
