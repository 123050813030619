/**
 * @file
 * Visual styles for buttons.
 */

.button,
.image-button {
  margin-right: 1em;
  margin-left: 1em;
}
.button:first-child,
.image-button:first-child {
  margin-right: 0;
  margin-left: 0;
}
