#bandeauMeteo {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #ffffff;
    padding: 16px;
    border-radius: 5px;
    p {
        font-family: "Public Sans", sans-serif;
        margin-bottom: 0px !important;
    }
    #serviceIndisponibleMeteo{
        display: flex;
        padding: 2px 16px 2px 16px;
        gap: 24px;
        p{
            font-size: 18px;
            font-weight: 400;
            line-height: 21.15px;
        }
        img{
            width: 40px;
            height: 40px;
        }
    }
    #ecowat-image{
        width: 95px;
        height: 28px;
        border-radius: 4px;
        padding: 4px;
        background-color: #28264C;
        vertical-align: bottom;
    }
    .btns-selected-card{
        display: flex;
        gap: 49px;
        justify-content: center;
        button{
            padding: 5px 16px 6px 16px;
            gap: 6px;
            border-radius: 3px;
            background-color: #33404B;
            color: #FFFFFF;
        }
        button:focus{
            box-shadow: unset;
        }
        button:hover{
            opacity: 0.9;
        }
    }
    .meteo-electrique{
        .desktop-only{
            display: block;
        }
        .mobile-and-tablet-only {
            display: none;
        }
        div{
            font-size: 14px;
            font-weight: 400;
            line-height: 16.45px;
        }
        button {
            font-size: 12px;
            font-weight: 700;
            line-height: 13.79px;
            border-color: revert;
        }
        button:active, button:focus{
            box-shadow: none;
        }
        #btn-redirect-monecowatt {
            display: flex;
            align-items: center;
            gap: 6px;
        }
    }
    #power-off {
        fill: #00A3E0;
    }
    @media screen and (max-width: 642px) {
        gap: 8px;
        .btns-selected-card{
            gap: 8px;
        }
        .meteo-electrique{
            gap: 6px;
        }
        .meteo-electrique, .btns-selected-card{
            flex-direction: column;
            button, p, div{
                align-self: center;
            }
        }
    }

    @media screen and (max-width: 940px) {
        .meteo-electrique{
            #ecowat-image{
                width: 58px;
                height: 19px;
            }
            .desktop-only{
                display: none;
            }
            .mobile-and-tablet-only {
                display: block;
                font-size: 12px;
            }
        }
    }

}

.encart {
    display: flex;
    align-items: center;
    margin: 16px 0px 16px 0px;
    border: 1px solid #EAAA00;
    border-radius: 5px;
    background-color: #ffffff;
    gap: 24px;
}

.text {
    margin: 16px 0 16px 0;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
}

.vector {
    margin: 16px 12px 16px 0;
}

.group31 {
    margin: 0px 0px 0px 12px;
    width: 45px;
    height: 45px;
}

.lien {
    font-weight: bold;
    color: black;
    text-decoration: underline;
}

@media screen and (max-width: 820px) {
    .encart {
        gap: 12px;
    }

    .text {
        margin: 8px 0 8px 0;
    }

    .group31 {
        margin: 0 8px 0 12px;
    }
}

.widget-link {
    text-decoration: none !important;
    max-width: 28%;
    display: flex;
  }

.widget {
    gap: 3px;
}

.widget-2d {
    flex: 1 1 auto;
}

h2{
    font-size: 11px !important;
}

.titre-mes-services{
    margin-bottom: 0px;
}

@media screen and (max-width: 991px) {
    .hidden-widget{
        display: none;
    }
}

.margeTop-Widget{
    margin-top: 24px !important;
}

@media screen and (max-width: 767px) {
    .margeTop-Widget {
        margin-top: 8px !important;
    }
}
