/**
 * @file
 * Markup generated by #type 'more_link'.
 */

.more-link {
  display: block;
  text-align: right; /* LTR */
}
[dir="rtl"] .more-link {
  text-align: left;
}
