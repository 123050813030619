/**
 * @file
 * Fixes for core/vendor/normalize-css/normalize.css since version 3.
 */

/**
 * Fix problem with details/summary lines missing the drop arrows.
 */
@media (min--moz-device-pixel-ratio: 0) {
  summary {
    display: list-item;
  }
}
