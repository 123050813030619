@import "~@odw/lnc-styles/lib/scss/abstracts/breakpoints";

#popins-bandeau-meteo {
  @import "~@odw/lnc-styles/lib/scss/main";
  lnc-web-modal {
    .lnc-modal-container {
      padding-right: 0px;
      padding-left: 0px;

      .lnc-modal-header {
        margin-right: 24px !important;
        margin-left: 24px !important;

        p {
          margin-bottom: 0px;
        }
      }

      lnc-web-select {
        margin-top: 30px;

        .dropdown-arrow{
          display: none !important;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 18.8px;
          text-align: left;
          margin-bottom: 0px;
        }

        #lnc-select-1 {
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
      }

      .row {
        margin-right: 0px;
        margin-left: 0px;
        flex-wrap: initial;
      }

      button:focus {
        outline: unset !important;
      }
    }
  }

  #modal-ecowatt-rouge-orange-feature-flipping-off, .modal_ecowatt_vert{
    .img_solution_modal_ecowatt_vert, .img_solution_modal_ecowatt_rouge-orange {
      @include device(tablet, desktop) {
        padding: 74.77px 75.49px 74.74px 74.79px;
      }
    }
    lnc-web-button {
      margin-top: -32px;
    }
  }

  #modal-ecowatt-rouge-orange-feature-flipping-on {
    p{
      font-family: "Public Sans", sans-serif !important;
      margin-bottom: 0px;
    }
    lnc-web-toast {
      lnc-banner{
        box-shadow: none;
      }
      .lnc-overlay-wrapper-banner{
        margin-top: 15px;
        margin-bottom: 30px !important;
        position: relative;
        max-width: none !important;
        padding-right: 0px !important;
        padding-left: 0px !important;
        right: 0px;
      }
    }
    .info-jour {
      display: flex;
      flex-wrap: wrap;
      gap: 40px;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        gap: 28px;
      }
      .info-banner{
        .banner-container{
          p{
            font-size: 14px;
            font-weight: 400;
            line-height: 16.45px;
            text-align: left;
          }
        }
      }

      #info_day_modal_ecowatt_rouge-orange-content{
        .info_day_modal_ecowatt_rouge-orange{
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
        }
      }
    }

    #tuile-content{
      display: flex;
      flex-direction: column;
      padding-top: 2px;
      gap: 6px;
      #label_jour{
        font-size: 16px;
        font-weight: 700;
        line-height: 18.8px;
        text-align: left;
      }

      .meteo-card {
        width: 240px;
        @media only screen and (max-width: 768px) {
          width: inherit;
        }
        @media only screen and (max-width: 940px) {
          flex-direction: row;
          padding: 8px;
          .meteo-icon-container {
              width: 40px;
              height: 44px;
            .meteo-icon {
                border-radius: 10px;
              svg {
                  width: unset !important;
                  height: unset !important;
              }
            }
          }
        }

        .meteo-description{
          .meteo-date-mobile-size {
            color: #ffffff;
            font-size: 12px;
          }
          @media only screen and (max-width: 940px){
            .meteo-message,
            .meteo-date {
              display: block;
            }

            .meteo-date-mobile-size {
              display: none;
            }
          }
        }
      }
    }

    #lnc-select-content{
      flex: 1;
    }
  }

  .modal_ecowatt_vert,
  .modal_ecowatt_rouge-orange {
    text-align: left;
    .img_solution_modal_ecowatt_vert,
    .img_solution_modal_ecowatt_rouge-orange {
      text-align: center;
    }
    img {
      display: inline;
    }
    @include device(desktop) {
      lnc-web-button {
        width: 127px;
      }
      img {
        width: 199.49px;
      }
    }
    @include device(tablet) {
      lnc-web-button {
        width: 127px;
      }
      img {
        width: 199.49px;
      }
    }
    @include device(mobile) {
      img {
        width: 200px;
      }
    }

    .text_modal_ecowatt_vert, .text_modal_ecowatt_rouge-orange{
      font-size: 16px;
      font-weight: 400;
      line-height: 18.8px;
      text-align: left;
    }
  }

  #modal-confirm-show-tasks{
    .lnc-modal-content{
      p{
        font-family: Public Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 18.8px;
        text-align: left;
      }
    }
  }
}