/**
 * @file
 * Styles for the system status counter component.
 */

.system-status-counter__status-icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: middle;
}
.system-status-counter__status-icon:before {
  display: block;
  content: "";
  background-repeat: no-repeat;
  background-position: center 2px;
  background-size: 20px;
}

.system-status-counter__status-icon--error:before {
  background-image: url(../../../images/core/icons/e32700/error.svg);
}
.system-status-counter__status-icon--warning:before {
  background-image: url(../../../images/core/icons/e29700/warning.svg);
}
.system-status-counter__status-icon--checked:before {
  background-image: url(../../../images/core/icons/73b355/check.svg);
}
