/**
 * @file
 * Visual styles for a nested tree child.
 */

div.tree-child {
  background: url(../../../images/core/tree.png) no-repeat 11px center; /* LTR */
}
div.tree-child-last {
  background: url(../../../images/core/tree-bottom.png) no-repeat 11px center; /* LTR */
}
[dir="rtl"] div.tree-child,
[dir="rtl"] div.tree-child-last {
  background-position: -65px center;
}
div.tree-child-horizontal {
  background: url(../../../images/core/tree.png) no-repeat -11px center;
}
