/**
 * @file
 * Visual styles for links.
 */

ul.inline,
ul.links.inline {
  display: inline;
  padding-left: 0; /* LTR */
}
[dir="rtl"] ul.inline,
[dir="rtl"] ul.links.inline {
  padding-right: 0;
  padding-left: 15px;
}
ul.inline li {
  display: inline;
  padding: 0 0.5em;
  list-style-type: none;
}
ul.links a.is-active {
  color: #000;
}
