/**
 * @file
 * Table drag behavior.
 *
 * @see tabledrag.js
 */

body.drag {
  cursor: move;
}
tr.region-title {
  font-weight: bold;
}
tr.region-message {
  color: #999;
}
tr.region-populated {
  display: none;
}
tr.add-new .tabledrag-changed {
  display: none;
}
.draggable a.tabledrag-handle {
  float: left; /* LTR */
  overflow: hidden;
  height: 1.7em;
  margin-left: -1em; /* LTR */
  cursor: move;
  text-decoration: none;
}
[dir="rtl"] .draggable a.tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}
a.tabledrag-handle:hover {
  text-decoration: none;
}
a.tabledrag-handle .handle {
  width: 14px;
  height: 14px;
  margin: -0.4em 0.5em 0;
  padding: 0.42em 0.5em;
  background: url(../../../images/core/icons/787878/move.svg) no-repeat 6px 7px;
}
a.tabledrag-handle:hover .handle,
a.tabledrag-handle:focus .handle {
  background-image: url(../../../images/core/icons/000000/move.svg);
}
.touchevents .draggable td {
  padding: 0 10px;
}
.touchevents .draggable .menu-item__link {
  display: inline-block;
  padding: 10px 0;
}
.touchevents a.tabledrag-handle {
  width: 40px;
  height: 44px;
}
.touchevents a.tabledrag-handle .handle {
  height: 21px;
  background-position: 40% 19px; /* LTR */
}
[dir="rtl"] .touch a.tabledrag-handle .handle {
  background-position: right 40% top 19px;
}
.touchevents .draggable.drag a.tabledrag-handle .handle {
  background-position: 50% -32px;
}
.tabledrag-toggle-weight-wrapper {
  text-align: right; /* LTR */
}
[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}
.indentation {
  float: left; /* LTR */
  width: 20px;
  height: 1.7em;
  margin: -0.4em 0.2em -0.4em -0.4em; /* LTR */
  padding: 0.42em 0 0.42em 0.6em; /* LTR */
}
[dir="rtl"] .indentation {
  float: right;
  margin: -0.4em -0.4em -0.4em 0.2em;
  padding: 0.42em 0.6em 0.42em 0;
}
