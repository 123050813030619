/**
 * @file
 * Styles for link buttons and action links.
 */

.action-links {
  margin: 1em 0;
  padding: 0;
  list-style: none;
}
[dir="rtl"] .action-links {
  /* This is required to win over specificity of [dir="rtl"] ul */
  margin-right: 0;
}
.action-links li {
  display: inline-block;
  margin: 0 0.3em;
}
.action-links li:first-child {
  margin-left: 0; /* LTR */
}
[dir="rtl"] .action-links li:first-child {
  margin-right: 0;
  margin-left: 0.3em;
}
.button-action {
  display: inline-block;
  padding: 0.2em 0.5em 0.3em;
  text-decoration: none;
  line-height: 160%;
}
.button-action:before {
  margin-left: -0.1em; /* LTR */
  padding-right: 0.2em; /* LTR */
  content: "+";
  font-weight: 900;
}
[dir="rtl"] .button-action:before {
  margin-right: -0.1em;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0.2em;
}
