/**
css avatar
**/
.fixed-bottom-right {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 999999;
}

#bouton-avatar {
  width: 48px;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  position: fixed;
  right: 0;
  bottom: 20%;
  z-index: 999999;
}

#volet-avatar {
  width: 25%;
  background-color: white;
  height: 100%;
  padding: 25px;
  overflow: auto;
}

#volet-avatar .block-title {
  color: #005eb8;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  text-align: left;
}

.volet-avatar-ouvert {
  right: 25% !important;
}

#close-avatar {
  width: 17px;
  height: 17px;
  position: absolute;
  right: 0;
  top: 2px;
}

.overlay-avatar {
  position: fixed;
  display: block !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

.volet-avatar-body {
  height: max-content;
}

@media screen and (max-width: 576px) {
  #volet-avatar {
    width: 100%;
  }
}
