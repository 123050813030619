/**
 * @file
 * Style another element as a link.
 */

button.link {
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: 0;
  background: transparent;
  font-size: 1em;
}
label button.link {
  font-weight: bold;
}
