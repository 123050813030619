/**
 * @file
 * Table sort indicator.
 */

th.is-active img {
  display: inline;
}
td.is-active {
  background-color: #ddd;
}
