/**
 * @file
 * Inline items.
 */

.container-inline div,
.container-inline label {
  display: inline;
}
/* Details contents always need to be rendered as block. */
.container-inline .details-wrapper {
  display: block;
}
