/**
 * @file
 * Default styles for the System Status general info.
 */

.system-status-general-info__item {
  margin-top: 1em;
  padding: 0 1em 1em;
  border: 1px solid #ccc;
}

.system-status-general-info__item-title {
  border-bottom: 1px solid #ccc;
}
