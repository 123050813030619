/**
 * @file
 * Styles for Classy's modal windows.
 */

.ui-dialog--narrow {
  max-width: 500px;
}

@media screen and (max-width: 600px) {
  .ui-dialog--narrow {
    min-width: 95%;
    max-width: 95%;
  }
}
