/**
 * @file
 * Styles for the system status report counters.
 */

.system-status-report-counters__item {
  width: 100%;
  margin-bottom: 0.5em;
  padding: 0.5em 0;
  text-align: center;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.063);
}

@media screen and (min-width: 60em) {
  .system-status-report-counters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .system-status-report-counters__item--half-width {
    width: 49%;
  }
  .system-status-report-counters__item--third-width {
    width: 33%;
  }
}
