/**
 * @file
 * Caption filter: default styling for displaying image captions.
 */

/**
 * Essentials, based on http://stackoverflow.com/a/13363408.
 */
.caption {
  display: table;
}
.caption > * {
  display: block;
  max-width: 100%;
}
.caption > figcaption {
  display: table-caption;
  max-width: none;
  caption-side: bottom;
}

/**
 * While editing and whenever the caption is empty, show a placeholder.
 *
 * Based on http://codepen.io/flesler/pen/AEIFc.
 */
.caption > figcaption[contenteditable="true"]:empty:before {
  content: attr(data-placeholder);
  font-style: italic;
}

/**
 * Caption filter: default styling for displaying Entity Embed captions.
 */
.caption .media .field,
.caption .media .field * {
  float: none;
  margin: unset;
}
